<template>
	<v-container fluid class="px-10">
		<v-row>
			<v-col cols="12">
				<h1 color="white">Team</h1>
				<v-divider class="my-3"></v-divider>
			</v-col>

			<v-col cols="12" v-if="teamLoading">
				<v-row>
					<v-col sm="12" md="3" lg="3" xl="3" cols="12" v-for="(item, index) in placeholder" :key="index">
						<v-skeleton-loader height="400" class="mx-auto rounded-xl" type="card"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-col>

			<v-col md="6" sm="12" lg="4" xl="4" cols="12" v-if="team.message">
				<h4>{{ team.message }}</h4>
			</v-col>

			<v-col v-else-if="!teamLoading && !team.message" md="6" sm="12" lg="4" xl="4" cols="12" v-for="streamer in team" :key="streamer.id" >
				<v-card 
					flat 
					tile
					target="_blank"
					:href="`https://www.twitch.tv/${streamer.login}`"
					:img="streamer.is_live ? `https://static-cdn.jtvnw.net/previews-ttv/live_user_${streamer.login}-1920x1080.jpg` : streamer.offline_image_url" 
					class="rounded-xl pa-0 d-flex justify-center align-stretch fill-height mgn_card"
					v-bind:class="{ 'is_live': streamer.is_live }"
				>
					<v-col cols="12" class="pa-0 overflow-hidden">
						<v-col cols="12" class="d-flex justify-center">
							<v-img 
								max-width="130" 
								class="profile_pic" 
								v-bind:class="{ 'is_live': streamer.is_live }"
								:src="streamer.profile_image_url"
								transition="scale-transition">
							</v-img>
						</v-col>

						<v-card-title class="d-flex justify-center">
							<h3>{{ streamer.display_name }}</h3>
						</v-card-title>

						<v-card-actions class="d-flex justify-center">
							<div v-for="social in streamer.social_links" :key="social.type">
								<v-btn elevation="0" color="primary" class="ma-2" fab :href="social.url" target="_blank">
									<font-awesome-icon v-if="social.type.toUpperCase() === 'discord'.toUpperCase()" icon="fa-brands fa-discord" size="lg"/>
									<font-awesome-icon v-else-if="social.type.toUpperCase() === 'tiktok'.toUpperCase()" icon="fa-brands fa-tiktok" size="lg"/>
									<v-icon v-else>mdi-{{ social.type.toLowerCase() }}</v-icon>
								</v-btn>
							</div>
						</v-card-actions>

						<v-col cols="12" v-if="streamer.description !== ''" class="d-flex justify-center align-start mgn_header fill-height">
							<p class="ma-0">{{ streamer.description }}</p>
						</v-col>
					</v-col>
				</v-card>
			</v-col>
			<v-col v-else-if="error">
				<h2>Error loading streamers. :(</h2>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			placeholder: 4,
			team: [],
			teamLoading: true,
			error: false,
		}
	},

	async created() {
		await this.fetchCore();
	},

	methods: {
		async fetchCore () {
			try {
				const request = await fetch(`${this.$store.state.base_url}/team`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.streams) {
						this.team = res.streams;
					}
					else {
						this.team = res;
					}

					this.teamLoading = false;
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
							this.loadingLoginBtn = false;
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.error = true;
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'An unexpected error occured.'
				});
			}
		},
	},
}
</script>

<style scoped>
.is_live {
	border: 5px solid #19CA6E;
	box-shadow: 5px solid #19CA6E;
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(25, 202, 110, 0.7);
	}

	70% {
		box-shadow: 0 0 0 15px rgba(25, 202, 110, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(25, 202, 110, 0);
	}
}
</style>